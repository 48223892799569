<template>
  <div class="editor" style="padding:20px;">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
        <em class="icon ni ni-bold"></em>
          <!-- <icon name="bold" /> -->
        </button>

        <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
        <em class="icon ni ni-italic"></em>
          <!-- <icon name="italic" /> -->
        </button>

        <!-- <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
        <i class="fa fa-strikethrough text-dark"></i>
           <icon name="strike" /> 
        </button>  -->

        <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
        <em class="icon ni ni-underline"></em>
          <!-- <icon name="underline" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
        <em class="icon ni ni-code"></em>
          <!-- <icon name="code" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
        <em class="icon ni ni-align-left"></em>
          <!-- <icon name="paragraph" /> -->
        </button>

        <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="menubar__button  bg-dark text-light"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
        <em class="icon ni ni-list"></em>
          <!-- <icon name="ul" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
        <em class="icon ni ni-list-ol"></em>
          <!-- <icon name="ol" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
        <em class="icon ni ni-quote-left"></em>
          <!-- <icon name="quote" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
        <em class="icon ni ni-code"></em>
          <!-- <icon name="code" /> -->
        </button>
        <button
          class="menubar__button bg-dark text-light"
          @click="commands.undo"
        >
          <em class="icon ni ni-curve-up-right"></em>
          <!-- <icon name="undo" /> -->
        </button>

        <button
          class="menubar__button bg-dark text-light"
          @click="commands.redo"
        >
          <em class="icon ni ni-curve-up-left"></em>
        </button>

      </div>
    </editor-menu-bar>

    <editor-content  class="editor__content" :editor="editor" />
  </div>
</template>

<script>
// import Icon from 'Components/Icon'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  props: {
    output: String,
  },
  components: {
    EditorContent,
    EditorMenuBar,
    // Icon,
  },
  methods: {
    setContent(value){ 
      this.editor.setContent(value)
    }
  },
  watch: { 
    output: function(newVal) { // watch it  
      if (newVal === '') {
        this.editor.clearContent()
      } 
    },
  },
  data() {
    return { 
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: '',
      }),
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  created () {
    this.editor.on('update', ({ getHTML }) => {
      let out = getHTML()
      this.output = out
      if (this.output === '<p></p>') {
        this.$emit('update:output', '')
      } else {
        this.$emit('update:output', out)
      }
    })
  },
}
</script>
<style>
  .ProseMirror{
      background-color: #fff;
      padding: 10px;
      line-height: 18px;
      color: #310130;
  }
  .menubar__button{
    -webkit-appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: buttonface;
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding: 5px 6px;
    border: solid 1px #31013014;
  }
</style>
